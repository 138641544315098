import React, { useEffect, useState } from 'react'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { updateLocalPref, getLocalPref } from '../scripts/Functions'

function Window({children, name, title, actions, overflow = 'hidden'}) {
    
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        let windowOptionsPref = getLocalPref(name)
        if(windowOptionsPref !== null){
            setCollapsed(windowOptionsPref.collapsed)
        }
    }, [])

    function changeCollapsedState(){
        let newState = !collapsed
        setCollapsed(newState)
        updateLocalPref(name, {
            collapsed: newState,
        })
    }

    return (
        <div className="window">
            <div className="window-head">
                <div className="collapse" onClick={changeCollapsedState}>
                    <FontAwesomeIcon icon={collapsed ? faChevronUp : faChevronDown} />
                </div>
                <div className="window-title">{title}</div>
                {actions}
            </div>
            <div className="window-content" style={{ height: collapsed ? 0 : 'auto', overflow: overflow }}>
                {children}
            </div>
        </div>
    )

}

export default Window