import React, { useContext, useState, useEffect } from 'react'
import { useInterval } from '../../scripts/Functions'
import moment from 'moment'
//import CSVReader from 'react-csv-reader'
import '../../App.css'

import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'
import Selectbox from '../../components/Selectbox'
import Loader from '../../components/Loader'

function ImportSettingsScreen({settings, setSettings}) {

    const { setLoading } = useContext(AppContext)
    
    const selectYears = Array.from(Array(10)).map((y, i) => moment().year() - i)

    const [exportOptions, setExportOptions] = useState({
        year: moment().year(),
    })

    const [exportFiles, setExportFiles] = useState([])



    function updateExportFiles(){
        
        authAxios.get('export-files')
        .then(response => {

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setExportFiles(response.data.exportFiles)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    useEffect(() => {
        updateExportFiles()
    }, [])

    useInterval(() => updateExportFiles(), 4 * 1000)


    function downloadExportFile(exportFile)
    {
        setLoading(true)

        authAxios.get('export-file/' + exportFile.id, { responseType: 'blob', timeout: 10000 })
        .then(response => {

            setLoading(false)

            let link = document.createElement('a')
            link.href = URL.createObjectURL(response.data)
            link.setAttribute('download', exportFile.file)
            document.body.appendChild(link)
            link.click()

        })
        .catch(error => {
            console.log(error)
        }) 

    }


    /* const [mcBkImport, setMcBkImport] = useState(null)
    const [mcPbImport, setMcPbImport] = useState(null)
    const [mcContactImport, setMcContactImport] = useState(null) */

    /* 
    function manacareBKImport(){

        if(mcBkImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/bk', {
            import: mcBkImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Benutzer importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function manacarePBImport(){

        if(mcPbImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/pb', {
            import: mcPbImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Benutzer importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    function manacareContactImport(){

        if(mcContactImport === null) return

        setLoading(true)
        
        authAxios.post('import/manacare/contact', {
            import: mcContactImport,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                console.log(response.data)
                alert(response.data.importCount + ' Kontakte importiert!')
            }

        })
        .catch(error => {
            console.log(error)
        })

    }
    */
    
    function exportCares(){

        setLoading(true)

        authAxios.get('export/cares/' + exportOptions.year, { timeout: 1000000 })
        .then(response => {
            //console.log(response)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <div className="page-content">

            <div className="window-row">

                <div className="col">

                    <Window name="settingsCareExport" title="Export" overflow="visible">                        
                        <div className="container">

                            <div className="row">
                                <div className="col" style={{ flex: .25 }}>
                                    <label>Jahr<br />
                                    <Selectbox options={selectYears.map(y => ({ label: y, value: y }))} name="year" state={exportOptions} setState={setExportOptions} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label><br /></label>
                                    <button onClick={exportCares}>Exportieren</button>
                                </div>
                            </div>

                        </div>
                    </Window>

                </div>
                <div className="col">

                    <Window name="settingsExportFiles" title="Export-Dateien">                        
                        <div className="container">

                            <div className="profile-table">
                                { exportFiles.length ?
                                    exportFiles.map((exportFile, index) => (
                                        <div key={index} className="row">
                                            <div className="col">
                                                { exportFile.file }
                                            </div>
                                            <div className="col auto">
                                                { exportFile.status === 'processing' &&
                                                    <div>
                                                        In Bearbeitung
                                                        <svg className="spinner dark small" viewBox="0 0 50 50">
                                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                        </svg>
                                                    </div>
                                                }
                                                { exportFile.status === 'finished' && <a onClick={() => downloadExportFile(exportFile)}>Download</a> }
                                            </div>
                                        </div>
                                    )) : (
                                    <div><small>Keine Exporte vorhanden.</small></div>
                                    )
                                }
                            </div>

                            
                            {/* <div className="row">
                                <div className="col">
                                    <CSVReader
                                    label="Betreuungskräfte CSV auswählen:"
                                    onFileLoaded={(data, fileInfo) => setMcBkImport(data)}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: true,
                                        skipEmptyLines: true,
                                        transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
                                    }} />
                                </div>
                                <div className="col">
                                    <button style={{marginTop: 25, height: 40}} onClick={() => manacareBKImport()}>Importieren</button>
                                </div>
                            </div> */}

                        </div>
                    </Window>

                </div>

            </div>

        </div>
    )

}

export default ImportSettingsScreen